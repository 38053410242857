(function (ng) {
    'use strict';
    ng.module('smart-table')
        .directive('stSelectAll', function () {
            return {
                restrict: 'E',

                template: '<md-checkbox  ng-model="isAllSelected">Velg hele siden</md-checkbox>',
                scope: {
                    all: '='
                },
                link: function (scope) {

                    scope.$watch('isAllSelected', function () {
                        if (angular.isDefined(scope.all)) {

                            scope.all.forEach(function (val) {
                                val.isSelected = scope.isAllSelected;
                            });
                        }
                    });

                    scope.$watch('all', function (newVal, oldVal) {
                        if (oldVal) {
                            oldVal.forEach(function (val) {
                                val.isSelected = false;
                            });
                        }

                        scope.isAllSelected = false;
                    });
                }
            };
        });
})(angular);