(function (ng) {
    'use strict';
    ng.module('smart-table')
        .directive('stMdSelect', function () {
            return {
                restrict: 'E',
                scope: {
                    predicate: '@',
                    selectable: '=',
                    items: '='
                },
                require: '^stTable',
                template: '<md-select ng-change="change()" multiple ng-model="selectedOptions" placeholder="Velg"><md-option ng-repeat="item in items" ng-value="{{item.value}}">{{item.name}}</md-option></md-select>',
                link: function (scope, element, attr, ctrl) {

                    function getSelectedOptions() {
                        var selectedOptions = [];

                        angular.forEach(scope.selectedOptions, function (item) {
                            selectedOptions.push(item.toString());
                        });

                        return selectedOptions;
                    }


                    scope.change = function change() {


                        var query = {
                            matchAny: {}
                        };

                        query.matchAny.items = getSelectedOptions();
                        var numberOfItems = query.matchAny.items.length;
                        if (numberOfItems === 0 || numberOfItems === scope.items.length) {
                            query.matchAny.all = true;
                        } else {
                            query.matchAny.all = false;
                        }


                        ctrl.search(query, scope.predicate);
                    }
                }
            }
        });
})(angular);