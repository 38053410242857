(function (ng) {
    'use strict';
    ng.module('app')
      .directive('mdSelectMenu', () => ({
        restrict: 'E',
        require: 'mdSelectMenu',
        link: (scope, elem, attrs, selectMenuCtrl) => {
          scope.$watchCollection(
            () => selectMenuCtrl.options,
            () => selectMenuCtrl.ngModel.$validate());
        },
      }))
})(angular);