// add filtersort to smart-table
// inserts md-list-item with all values
// use filter-list tag
(function (ng) {
    "use strict";
    ng.module("smart-table")
        .directive("filterList", function () {
            return {
                restrict: "E",
                scope: {
                    predicate: "@",
                    selectable: "=",
                    items: "="
                },
                require: "^stTable",
                template:
                    '<md-list-item ng-repeat="itm in items track by $index" ng-class="selectable.indexOf(itm.name) === -1 ? \'disabled\' : \'\'" ><md-checkbox aria-label="{{itm.name}}" ng-model="itm.selected"  ng-change="change()" ng-disabled="selectable.indexOf(itm.name) === -1" style="margin-right: 10px;"></md-checkbox> <md-truncate flex><md-tooltip>{{ itm.name }}</md-tooltip>{{ itm.name }}</md-truncate></md-list-item>',
                link: function (scope, element, attr, ctrl) {

                    function getSelectedOptions() {
                        var selectedOptions = [];

                        angular.forEach(scope.items, function (item) {
                            if (item.selected) {
                                selectedOptions.push(item.name);
                            }
                        });

                        return selectedOptions;
                    }


                    scope.change = function change() {


                        var query = {
                            matchAny: {}
                        };

                        query.matchAny.items = getSelectedOptions();
                        var numberOfItems = query.matchAny.items.length;
                        if (numberOfItems === 0 || numberOfItems === scope.items.length) {
                            query.matchAny.all = true;
                        } else {
                            query.matchAny.all = false;
                        }


                        ctrl.search(query, scope.predicate);
                    }
                }
            }
        });
})(angular);