(function (ng) {
    'use strict';
    var file_saver_1 =require('file-saver'); 
    require('jszip');

    ng.module('smart-table')
        .directive('stExport', stExport);

    stExport.$inject = ['$filter'];
    function stExport ($filter) {
            return {
                scope: {
                    visible: '=',
                    columns: '=',
                    fileName: '@'
                },
                require: '^stTable',
                link: function (scope, element, attr, ctrl) {
                    element.bind('click',
                        function () {


                            scope.calculateAge = function (birthday) { // birthday is a date
                                var dateTime = new Date(birthday);
                                var ageDifMs = Date.now() - dateTime.getTime();
                                var ageDate = new Date(ageDifMs); // miliseconds from epoch
                                return Math.abs(ageDate.getUTCFullYear() - 1970);
                            }


                            scope.showCol = function (colName) {
                                if (!angular.isDefined(scope.visible)) {
                                    return true;
                                } else {
                                    return (scope.visible.filter(function (v) {
                                        return v === colName;
                                    }))[0];
                                }
                            };

                            scope.findProp = function (obj, prop, defval) {
                                if (typeof defval === 'undefined') {
                                     defval = null;
                                }
                                prop = prop.split('.');
                                for (var i = 0; i < prop.length; i++) {
                                    if (obj[prop[i]] === null) {
                                        return defval;
                                    }
                                    obj = obj[prop[i]];
                                }

                                return obj;

                            }
                            function getRowValues(rowValues,row, col) {

                                switch (col.source) {
                                    case "networkEngagement":
                                        var fValue = [];
                                        angular.forEach(scope.findProp(row, col.source),
                                            function (value) {
                                                fValue.push(value.resourceNetwork.name);
                                            });
                                        rowValues.push(fValue.join(', '));
                                        break;
                                    //case "birthDate":
                                    //    rowValues.push(scope.calculateAge(scope.findProp(row, col.source)));
                                    //    break;
                                  case "networkEngagement.introCource":
                                    var aaValue = [];
                                    angular.forEach(scope.findProp(row, 'networkEngagement'),
                                      function (value) {
                                        if (value.introCource !== null) {
                                          aaValue.push(value.introCource ? "Ja" : "Nei");
                                        }
                                      });
                                    rowValues.push(aaValue.join(','));
                                        break;
                                    case "workplace.institution.institutionType.name":
                                        var v = scope.findProp(row, col.source);
                                        if (angular.isUndefined(v) || v === null) {
                                            v = "";
                                        }
                                        rowValues.push(v);
                                        break;
                                    case "networkEngagement.region":
                                        var aValue = [];
                                        angular.forEach(scope.findProp(row, 'networkEngagement'),
                                            function (value) {
                                                if (value.region !== null) {
                                                    aValue.push(value.region.regionName);
                                                }
                                            });
                                        rowValues.push(aValue.join(','));
                                        break;
                                    case "networkEngagement.networkEngagementFunction":
                                        var bValue = [];
                                        angular.forEach(scope.findProp(row, 'networkEngagement'),
                                            function (net) {
                                                angular.forEach(net.networkEngagementFunction,
                                                    function (value) {
                                                        bValue.push(value.function.name);
                                                    });
                                            });
                                        rowValues.push(bValue.join(','));
                                        break;
                                    case "givenName":
                                        rowValues.push($filter('titleCase')(scope.findProp(row, col.source)));
                                        break;
                                    case "sureName":
                                        rowValues.push($filter('titleCase')(scope.findProp(row, col.source)));
                                        break;
                                    case "healthCareRoles":
                                        var cValue = [];
                                        angular.forEach(scope.findProp(row, col.source),
                                            function (value) {
                                                cValue.push(value.codeText);
                                            });
                                        rowValues.push(cValue.join(','));
                                    break;
                                    case "specialities":
                                      var sValue = [];
                                      angular.forEach(scope.findProp(row, col.source),
                                        function (value) {
                                          sValue.push(value.codeText);
                                        });
                                      rowValues.push(sValue.join(','));
                                      break;
                                    case "phones":
                                        var dValue = [];
                                        angular.forEach(scope.findProp(row, col.source),
                                            function (value) {
                                                dValue.push(value.number);
                                            });
                                        rowValues.push(dValue.join(','));
                                        break;
                                    case "mails":
                                        var fValue = [];
                                        angular.forEach(scope.findProp(row, col.source),
                                            function (value) {
                                              fValue.push(value.address + ";");
                                            });
                                        rowValues.push(fValue.join(';'));
                                        break;
                                    case "workplace":
                                        var work = scope.findProp(row, col.source);

                                        if (work && work.name !== null && work.name.length > 0) {
                                            rowValues.push(work.name);
                                        } else {
                                            rowValues.push("");
                                        }
                                        break;
                                    case "manager":
                                        var man = scope.findProp(row, col.source);

                                        if (man && man.givenName !== null && man.givenName.length > 0) {
                                            rowValues.push($filter('titleCase')(man.sureName + ", " + man.givenName));
                                        } else {
                                            rowValues.push("");
                                        }
                                    break;
                                  case "manager.mails":
                                    var manMail = scope.findProp(row, col.source);
                                    var eValue = [];
                                    angular.forEach(manMail, function (net) {
                                      eValue.push(net.address +";");
                                    });
                                    rowValues.push(eValue.join(';'));
                                      break;
                                    default:
                                        rowValues.push("");
                                        break;
                                };
                                return rowValues;
                            }

                            scope.data = [];
                            var headers = [];
                            var colLength = [];
                            var keys = [];
                            //Header
                            angular.forEach(scope.columns,
                                function (itm) {
                                    if (itm.header !== "" && scope.showCol(itm.header)) {
                                        headers.push(itm.header);
                                        colLength.push(itm.header.length);
                                        keys.push(itm.source);
                                    }
                                });

                            scope.data.push(headers);
                            // Data 
                            var tableRows = ctrl.getFilteredCollection();

                            // henter bare fra keys
                            if (scope.fileName !== "Ressursliste") {
                                // henter bare fra keys
                                angular.forEach(tableRows,
                                    function (row) {
                                        var rowValues = keys.map(function (key) {
                                            return row[key];
                                        });
                                        scope.data.push(rowValues);
                                    });
                            } else {
                                // gjør spesialtilpassninger for personlisten pga dyp json struktur
                                angular.forEach(tableRows,
                                    function (row) {
                                        var rowValues = [];
                                        angular.forEach(scope.columns,
                                            function (col) {

                                                if (scope.showCol(col.header)) {
                                                    rowValues = getRowValues(rowValues,row, col);

                                                };
                                            });
                                        scope.data.push(rowValues);
                                    });
                            }
                            // setter column width
                            angular.forEach(scope.data,
                                function (row) {
                                    for (var i = 0; i < colLength.length; i++) {

                                        if (colLength.length === row.length && colLength[i] < row[i].length) {
                                            colLength[i] = row[i].length;
                                        };
                                    }
                                });

                            // exporterer til excel

                            function datenum(v, date1904) {
                                if (date1904) {v += 1462;}
                                var epoch = Date.parse(v);
                                return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
                            };

                            function getSheet(data) {
                                var ws = {};
                                var range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
                                for (var r = 0; r !== data.length; ++r) {
                                    for (var c = 0; c !== data[r].length; ++c) {
                                        if (range.s.r > r) {range.s.r = r;}
                                        if (range.s.c > c) {range.s.c = c;}
                                        if (range.e.r < r) {range.e.r = r;}
                                        if (range.e.c < c) {range.e.c = c;}
                                        var cell = { v: data[r][c] };
                                        if (cell.v === null) { continue;}
                                        var cell_ref = XLSX.utils.encode_cell({ c: c, r: r });

                                        if (typeof cell.v === 'number') { cell.t = 'n'; }
                                        else if (typeof cell.v === 'boolean') { cell.t = 'b'; }
                                        else if (cell.v instanceof Date) {
                                            cell.t = 'n'; cell.z = XLSX.SSF._table[14];
                                            cell.v = datenum(cell.v);
                                        }
                                        else {cell.t = 's';}
                                        if (r === 0) {
                                            cell.s = {
                                                fill: {
                                                    patternType: "none",
                                                    fgColor: { rgb: "#ffcc00" },
                                                    bgColor: { rgb: "#ffcc00" }
                                                },
                                                font: {
                                                    name: 'Arial',
                                                    sz: 12,
                                                    color: { rgb: "#99ff99" },
                                                    bold: true,
                                                    italic: false,
                                                    underline: false
                                                },

                                                border: {
                                                    top: { style: "thin", color: { auto: 1 } },
                                                    right: { style: "thin", color: { auto: 1 } },
                                                    bottom: { style: "thin", color: { auto: 1 } },
                                                    left: { style: "thin", color: { auto: 1 } }
                                                }
                                            }
                                        } else {
                                            cell.s = {
                                                font: {
                                                    name: 'Arial',
                                                    sz: 10,
                                                    bold: false,
                                                    italic: false,
                                                    underline: false
                                                },

                                                border: {
                                                    top: { style: "thin", color: { auto: 1 } },
                                                    right: { style: "thin", color: { auto: 1 } },
                                                    bottom: { style: "thin", color: { auto: 1 } },
                                                    left: { style: "thin", color: { auto: 1 } }
                                                }
                                            }
                                        }
                         
                                        ws[cell_ref] = cell;
                                    }
                                }
                                if (range.s.c < 10000000) {
                                    ws['!ref'] = XLSX.utils.encode_range(range);
                                }

                                ws['!cols'] = [];
                                //Setting Dynamic column width

                                colLength.forEach(function (headerlength) {
                                    ws['!cols'].push({ "wch": headerlength });
                                });

                                //Freezing the columns
                                ws['!freeze'] = { xSplit: "1", ySplit: "1", topLeftCell: "B2", activePane: "bottomRight", state: "frozen" }



                                return ws;
                            };

                            function Workbook() {
                                if (!(this instanceof Workbook)) {
                                    return new Workbook();
                                }
                                this.SheetNames = [];
                                this.Sheets = {};
                            }

                            var wb = new Workbook(), ws2 = getSheet(scope.data);
                            /* add worksheet to workbook */
                            wb.SheetNames.push(scope.fileName);
                            wb.Sheets[scope.fileName] = ws2;
                            var wbout = XLSX.write(wb, {
                                autoWidth: true,
                                bookType: 'xlsx',
                                bookSST: true,
                                type: 'binary'
                            });
                            function s2ab(s) {
                                var buf = new ArrayBuffer(s.length);
                                var view = new Uint8Array(buf);
                                for (var i = 0; i !== s.length; ++i) {
                                     view[i] = s.charCodeAt(i) & 0xFF;
                                }
                                return buf;
                            }

                            file_saver_1.saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), scope.fileName + '.xlsx');
                        });
                }
            }

        };
})(angular);