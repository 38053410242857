(function (ng) {
    'use strict';
    ng.module('smart-table')
        .directive('stMail', ['$window',  function ($window) {
            return {
                scope: {
                    columns: '='
                },
                require: '^stTable',
              link: function (scope, element, attr, ctrl) {


            
                    element.bind('click',
                        function () {

                            var tableRows = ctrl.getFilteredCollection();
                            var selected = tableRows.filter(function (item) {
                                return item.isSelected === true;
                            });



                            scope.showCol = function (colName) {
                                return (scope.visible.filter(function (v) {
                                    return v === colName;
                                }))[0];
                            };
                            scope.findProp = function (obj, prop, defval) {
                                if (typeof defval == 'undefined') {defval = null;}
                                prop = prop.split('.');
                                for (var i = 0; i < prop.length; i++) {
                                    if (obj[prop[i]] === null) {
                                        return defval;
                                    }
                                    obj = obj[prop[i]];
                                }
                                return obj;
                            }
                            var mails = [];


                            angular.forEach(selected,
                                function (row) {
                                    angular.forEach(scope.findProp(row, 'mails'),
                                        function (value) {
                                            mails.push(value.address);
                                        });
                                });
                          var execString = "mailto:" + mails.join(';') + "?subject=Melding fra ressursliste";

                          if (execString.length < 1900) {
                            $window.open(execString, "_self");
                          } else {
                            copy(mails.join(';'));
                            if (confirm(
                              "For mange mailadresser til å automatisk opprette mail.\n Har kopiert adressene til utklippstavlen.\n\nDu må selv lime dem inn i tilfeltet.")
                            ) {
                              $window.open("mailto:?subject=Melding fra ressursliste", "_self");
                            } else {
                              alert("Alternativt kan du hente mailadressene fra excellisten");
                            }

                          }
                  });

                function copy(input) {
                  var text_to_share = input;

                  // create temp element
                  var copyElement = document.createElement("span");
                  copyElement.appendChild(document.createTextNode(text_to_share));
                  copyElement.id = 'tempCopyToClipboard';
                  angular.element(document.body.appendChild(copyElement));

                  // select the text
                  var range = document.createRange();
                  range.selectNode(copyElement);
                  window.getSelection().removeAllRanges();
                  window.getSelection().addRange(range);

                  // copy & cleanup
                  document.execCommand('copy');
                  window.getSelection().removeAllRanges();
                  copyElement.outerHTML = "";//.remove();
                }
              }

            }

        }]);
})(angular);